// Signal to babel which polyfills oidc-client needs
if (typeof Promise !== "function") {
}

if (!Array.from) {
}

if (!Array.prototype.find) {
}

if (!String.prototype.startsWith) {
}

if (typeof Object.assign !== "function") {
}
